import React from 'react';
import styled from 'styled-components';


function Header () {
    return (
      <>
        <h1>Catter</h1>
        <p style={{padding: '1em'}}>The app that nobody asked for, but aren't too upset about discovering.</p>
      </>
    )
}

export default Header;