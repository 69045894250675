import React from 'react';

const catTiles = [
  "Aye its a cat!",
  "Wow look at this one",
  "can I get a meow?",
  "Aw how cute :)",
  "This one is my favorite",
  "Cat time!!",
  "Lol what a lil stinker",
  "What do you think of this one?",
  "I could do this all day!",
  "What a furry lil baby",
  "AHHHHH fuzzy babyyy"
]

export default catTiles;